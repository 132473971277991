import axios from '@/api/config/interceptor'
import {
    host
} from '@/api/config/host'
import {
    _paramsToQueryString
} from '@/utils/utils'

// 获取统计列表 
export const GetInvoiceReports = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceTask/GetInvoiceReports`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 统计查询-下载
export const DownloadInvoiceReports = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceTask/DownloadInvoiceReports`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 统计查询-下载所有表格
export const DownloadWaybill = (params,type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceTask/DownloadInvoiceReportsByAll`, params,type).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 统计查询-汇总
export const GetInvoiceReportSum = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceTask/GetInvoiceReportSum`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 统计明细查询
export const GetInvoiceReportDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceTask/GetInvoiceReportDetails`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 统计明细-下载
export const DownloadInvoiceReportDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceTask/DownloadInvoiceReportDetails`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 统计明细-汇总
export const GetInvoiceReportDetailSum = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceTask/GetInvoiceReportDetailSum`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}