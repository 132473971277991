<template>
  <div class="statistics">
    <div class="facilityBox">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" inline :model="searchForm">
        <el-form-item label="货主名称：" prop="companyName" label-width="100px">
          <el-input
            v-model="searchForm.companyName"
            placeholder="货主名称"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="业务类型："
          prop="businessTypeCode"
          label-width="100px"
        >
          <el-select
            v-model="searchForm.businessTypeCode"
            placeholder="请选择业务类型"
            style="width: 180px"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="9%增值税发票" value="1"></el-option>
            <el-option label="3%增值税发票" value="2"></el-option>
            <el-option label="1%增值税发票" value="4"></el-option>
            <el-option label="平台技术服务" value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="票制：" prop="invoicingCode" label-width="80px">
          <el-select
            v-model="searchForm.invoicingCode"
            placeholder="请选择票制"
            style="width: 180px"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="一票制" value="1"></el-option>
            <el-option label="两票制" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务组：" prop="verifyRemark" label-width="100px">
          <el-input
            v-model="searchForm.verifyRemark"
            placeholder="审核信息、备注、关键字搜索"
            style="width: 220px"
          ></el-input>
        </el-form-item>
        <el-button type="primary" icon="el-icon-zoom-in" @click="search"
          >搜索</el-button
        >
        <el-button
          icon="el-icon-delete"
          @click="$refs['searchForm'].resetFields()"
          >清空</el-button
        >
        <el-button
          icon="el-icon-download"
          :disabled="!pagination.list.length"
          type="primary"
          size="medium"
          @click="exportExcel"
          >导出表格
        </el-button>
        <el-button
          icon="el-icon-download"
          :disabled="!pagination.list.length"
          type="primary"
          size="medium"
          @click="exportWaybill"
          >导出运单表格
        </el-button>
        <el-form-item prop="time" label-width="100px">
          <el-select
            v-model="searchForm.timeType"
            style="width: 180px;margin-left: 20px;"
          >
            <el-option label="付款日期：" value="Pay"></el-option>
            <el-option label="服务商同意开票日期：" value="Agree"></el-option>
            <el-option label="服务商已开票日期：" value="Over"></el-option>
          </el-select>
          <el-date-picker
            v-model="searchForm.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束时间"
            style="width: 390px"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="settleBox">
        <!--数量金额总计-->
        <div style="display:flex; margin: 0 0 0 30px;">
          <div style="color:rgb(255, 139, 23); white-space: nowrap;">
            <span style="color:#333;font-weight: bold;">运费含税金额：</span
            >{{
              totalData.WaybillTotalAmount
                ? "￥" + totalData.WaybillTotalAmount
                : "/"
            }}
          </div>
          <div
            style="color:rgb(255, 139, 23); margin: 0 30px; white-space: nowrap;"
          >
            <span style="color:#333;font-weight: bold;"
              >技术服务费含税金额：</span
            >{{
              totalData.TecServiceFee ? "￥" + totalData.TecServiceFee : "/"
            }}
          </div>
          <div style="color:rgb(255, 139, 23); white-space: nowrap;">
            <span style="color:#333;font-weight: bold;">应收金额：</span
            >{{ totalData.TotalAmount ? "￥" + totalData.TotalAmount : "/" }}
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <el-table
        :data="pagination.list"
        v-loading="pagination.loading"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
      >
        <el-table-column
          align="center"
          type="index"
          prop="index"
          label="序号"
          width="50"
          fixed="left"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="VerifyRemark"
          label="业务组"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CompanyName"
          label="单位名称"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="BusinessTypeName"
          label="业务类型"
          width="340"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Rate"
          label="费率"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="InvoicingName"
          label="票制"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="WaybillTotalAmount"
          label="运费含税金额"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{
                scope.row.WaybillTotalAmount
                  ? "￥" + scope.row.WaybillTotalAmount
                  : "/"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TecServiceFee"
          label="技术服务费含税金额"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{
                scope.row.TecServiceFee ? "￥" + scope.row.TecServiceFee : "/"
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="TotalAmount"
          label="应收金额"
          width="200"
        >
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.TotalAmount ? "￥" + scope.row.TotalAmount : "/" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="PayDateTime"
          label="付款日期"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="160">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="goDetail(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="display:flex; align-items:center;margin-top: 10px">
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetInvoiceReports,
  GetInvoiceReportSum,
  DownloadInvoiceReports,
  DownloadWaybill,
} from "@/api/finance/statistics/index";
export default {
  name: "statistics",
  data() {
    return {
      searchForm: {
        companyName: "", //货主名称
        businessTypeCode: "", //业务类型
        invoicingCode: "", //票制
        time: ["", ""],
        verifyRemark: "", // 业务组
        timeType: "Pay", // 时间类型
      },
      pagination: {
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
        list: [],
      },
      // 总计
      totalData: {
        WaybillTotalAmount: "/",
        TecServiceFee: "/",
        TotalAmount: "/",
      },
    };
  },
  methods: {
    //获取统计列表
    getStatisticsList() {
      this.pagination.loading = true;
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        companyName: this.searchForm.companyName,
        verifyRemark: this.searchForm.verifyRemark,
        timeType: this.searchForm.timeType,
        businessTypeCode: this.searchForm.businessTypeCode,
        invoicingCode: this.searchForm.invoicingCode,
        startTime: this.searchForm.time ? this.searchForm.time[0] : "",
        endTime: this.searchForm.time ? this.searchForm.time[1] : "",
      };
      GetInvoiceReports(params)
        .then((res) => {
          this.pagination.list = res.data.DataList.map((item, index) => {
            item.index =
              (this.pagination.page - 1) * this.pagination.pagesize + index + 1;
            return item;
          });
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.pagination.loading = false;
        });
      this.getInvoiceReportSum();
    },
    //获取统计列表总计
    getInvoiceReportSum() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        companyName: this.searchForm.companyName,
        verifyRemark: this.searchForm.verifyRemark,
        timeType: this.searchForm.timeType,
        businessTypeCode: this.searchForm.businessTypeCode,
        invoicingCode: this.searchForm.invoicingCode,
        startTime: this.searchForm.time ? this.searchForm.time[0] : "",
        endTime: this.searchForm.time ? this.searchForm.time[1] : "",
      };
      GetInvoiceReportSum(params)
        .then((res) => {
          this.totalData = {
            WaybillTotalAmount: res.data.WaybillTotalAmountSum,
            TecServiceFee: res.data.TecServiceFeeSum,
            TotalAmount: res.data.TotalAmountSum,
          };
        })
        .finally(() => {});
    },
    //搜索
    search() {
      this.pagination.loading = true;
      this.pagination.page = 1;
      this.getStatisticsList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.loading = true;
      this.pagination.page = e;
      this.getStatisticsList();
    },
    // 查看详情
    goDetail(item) {
      this.$router.push({
        path: "/finance/statistics/statisticsInfo",
        query: {
          searchCompanyName: this.searchForm.companyName,
          searchBusinessTypeCode: this.searchForm.businessTypeCode,
          searchInvoicingCode: this.searchForm.invoicingCode,
          startTime: this.searchForm.time ? this.searchForm.time[0] : "",
          endTime: this.searchForm.time ? this.searchForm.time[1] : "",
          itemInfo: JSON.stringify(item),
        },
      });
    },
    //导出excel
    exportExcel() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        companyName: this.searchForm.companyName,
        verifyRemark: this.searchForm.verifyRemark,
        timeType: this.searchForm.timeType,
        businessTypeCode: this.searchForm.businessTypeCode,
        invoicingCode: this.searchForm.invoicingCode,
        startTime: this.searchForm.time ? this.searchForm.time[0] : "",
        endTime: this.searchForm.time ? this.searchForm.time[1] : "",
      };
      DownloadInvoiceReports(params).then((res) => {
        window.location.href = res.data;
      });
    },
    //导出运单表格
    exportWaybill() {
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        companyName: this.searchForm.companyName,
        verifyRemark: this.searchForm.verifyRemark,
        timeType: this.searchForm.timeType,
        businessTypeCode: this.searchForm.businessTypeCode,
        invoicingCode: this.searchForm.invoicingCode,
        startTime: this.searchForm.time ? this.searchForm.time[0] : "",
        endTime: this.searchForm.time ? this.searchForm.time[1] : "",
      };

      const loading = this.$loading({
        lock: true,
        text: "导出表格中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      DownloadWaybill(params, {
        responseType: "blob",
      }).catch((err) => {
        let { data } = err;
        let that = this;
        if (!data.type.includes("zip")) {
          let reader = new FileReader();
          reader.readAsText(data);
          reader.onload = function(e) {
            const { msg, title } = JSON.parse(reader.result);
            that.$message({
              message: `下载失败 ${msg || title}`,
              type: "error",
            });
            loading.close();
          };
          return;
        }
        const blob = new Blob([data]);
        let fileName = err.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]; //拆解获取文件名，
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        loading.close();
      });
    },
  },
  created() {
    this.getStatisticsList();
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .settleBox {
    margin: 15px 0px;
    padding: 10px 16px;
    background-color: #f0f0f0;
    color: #666;
  }
}
</style>
